import router from '@/router'

const API_LOGIN_ROUTE = '/api/sso/login'

/**
 * Return the api login route with the current url encoded as a param (referrer). This is passed to the
 * api sso controller, forwarded to auth soo, and then back to the api sso controller so when the user
 * has been authenticated we can redirect the frontend back to that route
 */
export const ieAuthLoginRoute = (clientId) => {
    const ieClientId = clientId || localStorage['app:most_recent_client']
    const buff = new Buffer(JSON.stringify({ referrer: router.history._startLocation }))
    const base64data = buff.toString('base64')
    return `${API_LOGIN_ROUTE}?referrer="${base64data}"&ie_client_id=${ieClientId}`
}

/**
 * Return the route referer or build a route using the backup params
 *
 * @param backupRouteParams - helpful when you don't want the home page or '/' to be
 *                            the referer (page refresh or copy /paste link)
 * @returns  Route
 */
export function safeReferer(backupRouteParams) {
    const { referer } = router

    if (referer.path === '/' && backupRouteParams) {
        return router.resolve(backupRouteParams).route
    }

    return referer
}
