<template>
    <v-container>
        <v-row justify="start">
            <v-col v-for="item in options" :key="item[idProp]" style="flex-grow: 0" class="pa-4">
                <button-icon-card
                    :title="item[titleProp]"
                    :selected="enabled(item[idProp])"
                    :image="imagePath(item[idProp], item[titleProp])"
                    @click="toggle(item[idProp])"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
πx
<script>
import ButtonIconCard from '@/components/cards/ButtonIconCard'
export default {
    components: { ButtonIconCard },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        /**
         * Options should be an Array of Objects with minimally a name value.
         */
        options: {
            type: Array,
            default: () => [],
        },
        titleProp: {
            type: String,
            default: 'name',
        },
        idProp: {
            type: String,
            default: 'id',
        },
        imagePath: {
            type: Function,
            default: (id, name) => {
                return null
            },
        },
    },
    computed: {},
    methods: {
        enabled(val) {
            return this.value.includes(val)
        },
        toggle(val) {
            let newValue = []

            if (this.value.includes(val)) {
                newValue = this.value.reduce(function (acc, v) {
                    if (v !== val) {
                        acc.push(v)
                    }

                    return acc
                }, [])
            } else {
                newValue = [...this.value, val]
            }

            this.$emit('change', newValue)
        },
    },
}
</script>

<style></style>
