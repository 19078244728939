<template>
    <v-card :loading="loading" flat>
        <instance-status-action
            v-for="status in availableButtons"
            :key="status"
            v-bind="$attrs"
            :status="status"
            :instance="instance"
        />
        <template v-if="sandboxEligible">
            <v-divider></v-divider>
            <sandbox-action v-bind="$attrs" :instance="instance" />
        </template>
    </v-card>
</template>
<script>
import InstanceStatusAction from '@/views/Imt/Instances/StatusAction'
import SandboxAction from '@/views/Imt/Instances/SandboxAction'
import availableStates from '@/mixins/available-instance-states'

export default {
    name: 'InstanceStatusActions',
    components: { InstanceStatusAction, SandboxAction },
    mixins: [availableStates],
    props: {
        instance: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    computed: {
        availableButtons() {
            return this.availableStates[this.instance.status] || []
        },
        sandboxEligible() {
            return this.instance.application === 'allthedata'
        },
    },
}
</script>
