<template>
    <child-layout :title="title">
        <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>

        <ag-grid-server-side
            id="instanceMgmt"
            :server-side-datasource="serverSideDatasource"
            :column-defs="columnDefs"
            :show-download-button="false"
        >
            <template v-if="allowCreate" v-slot:controls>
                <v-btn
                    :to="{
                        name: 'instance-create',
                        query: { clientId: query != null ? query.clientId : null },
                    }"
                    :disabled="!hasRole([ROLE_IMT_INSTANCES_CREATE])"
                    class="primary ml-4"
                    >Create Instance
                </v-btn>
                <div class="button-space"></div>
                <ExportToCSVButton
                    :fetch-all-data="fetchAllInstanceData"
                    filename="InstancesExport.csv"
                />
            </template>
        </ag-grid-server-side>
    </child-layout>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import InstanceListActions from '@/views/Imt/Instances/ListActions'
import ColumnDef from '@/helpers/ag-grid/columnDef/imt/instances'
import pagination from '@/helpers/ag-grid/pagination/rest'
import ChildLayout from '@/components/layout/ChildLayout'
import { ROLE_IMT_INSTANCES_CREATE } from '@/helpers/security/roles'
import { mapGetters, mapActions } from 'vuex'
import ExportToCSVButton from '@/components/table/ExportToCSVButton'

export default {
    name: 'IMTInstances',
    components: {
        ChildLayout,
        AgGridServerSide,
        // eslint-disable-next-line vue/no-unused-components
        InstanceListActions,
        ExportToCSVButton,
    },
    props: {
        title: {
            type: String,
            default: 'Instance Management',
        },
        query: {
            type: Object,
            default: undefined,
        },
        allowCreate: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        columnDefs: ColumnDef,
        ROLE_IMT_INSTANCES_CREATE: ROLE_IMT_INSTANCES_CREATE,
        currentId: null,
        currentParams: null,
    }),
    computed: {
        ...mapGetters('auth', ['hasRole']),
        ...mapGetters({
            items: 'instance/getItems',
            metadata: 'instance/getMetadata',
        }),
    },
    methods: {
        ...mapActions({
            fetchInstances: 'instance/cget',
            fetchInstance: 'instance/get',
        }),
        serverSideDatasource: function () {
            const fetch = async ({ id, ...queryPayload }) => {
                //clear currentId and currentParams
                this.currentId = null
                this.currentParams = null
                if (id) {
                    this.currentId = id
                    await this.fetchInstance(id)
                    return { items: this.items, count: this.items.length }
                }
                if (this.query) {
                    queryPayload = { ...this.query, ...queryPayload }
                }

                this.currentParams = queryPayload
                await this.fetchInstances({ params: queryPayload })

                return {
                    items: this.items,
                    count: this.metadata.count,
                }
            }

            return {
                getRows: async function (params) {
                    const { items, count } = await fetch(pagination(params))
                    params.successCallback(items, count)
                },
            }
        },
        // Fetch all data and update exportData
        async fetchAllInstanceData() {
            let dataItems = []
            let paramVal = this.currentParams
            // After fetching the data, update exportData
            if (this.currentId) {
                const apiResponse = await this.fetchInstance(this.currentId)
                return dataItems.concat(apiResponse)
            } else {
                const loopCount = Math.ceil(this.metadata.count / this.currentParams.size)

                for (let i = 1; i <= loopCount; i++) {
                    if (paramVal && paramVal.hasOwnProperty('page')) {
                        paramVal.page = i

                        const updatedParams = paramVal
                        const clientsApiResponse = await this.fetchInstances({
                            params: updatedParams,
                        })

                        dataItems = dataItems.concat(clientsApiResponse)
                    }
                }
                return dataItems
            }
        },
    },
}
</script>

<style scoped>
.button-space {
    margin-left: 5px;
}
</style>