<template>
    <base-dialog
        v-if="validStatus"
        v-model="opened"
        :title="title"
        confirm-text="Confirm"
        :confirm-action="submit"
        v-on="$listeners"
    >
        <template v-slot:activator="{ on, attrs }">
            <gated-list-item
                :allowed-roles="statusAttr.roles"
                style="vertical-align: middle"
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-title>
                    <v-icon size="14" left color="#050F2D" style="min-width: 16px">
                        {{ statusAttr.icon }}
                    </v-icon>
                    {{ statusText }}
                </v-list-item-title>
            </gated-list-item>
        </template>
        <template v-slot:content>
            <div class="ma-4">{{ confirmText }}</div>
        </template>
    </base-dialog>
</template>
<script>
import roles from '@/helpers/security/roles'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog'
import { upperCase } from '@/helpers/shared'
import { errorSnackbar, successSnackbar } from '@/helpers/snackbar'
import GatedListItem from '@/components/security/GatedListItem'
import { safeReferer } from '@/helpers/router'

export default {
    name: 'InstanceStatusAction',
    components: {
        GatedListItem,
        BaseDialog,
    },
    props: {
        instance: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        status: {
            type: String,
            default: '',
            required: true,
        },
        withRedirect: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        opened: false,
        title: 'Confirm Instance State Change',
        statusMap: {
            requestingDeployment: {
                text: 'deploy',
                roles: [roles.ROLE_IMT_INSTANCES_DEPLOY],
                color: 'success',
                icon: 'fal fa-rocket',
                sandboxOnly: false,
            },
            requestingDeactivation: {
                text: 'deactivate',
                roles: [roles.ROLE_IMT_INSTANCES_DEACTIVATE],
                color: 'danger',
                icon: 'fal fa-power-off',
                sandboxOnly: false,
            },
            requestingActivation: {
                text: 'activate',
                roles: [roles.ROLE_IMT_INSTANCES_ACTIVATE],
                color: 'success',
                icon: 'fal fa-plug',
                sandboxOnly: false,
            },
            requestingArchival: {
                text: 'archive',
                roles: [roles.ROLE_IMT_INSTANCES_ARCHIVE],
                color: 'warning',
                icon: 'fal fa-archive',
                sandboxOnly: false,
            },
            requestingDeletion: {
                text: 'delete',
                roles: [roles.ROLE_IMT_INSTANCES_DELETE],
                color: 'danger',
                icon: 'fal fa-trash',
                sandboxOnly: true,
            },
            requestingRestore: {
                text: 'restore',
                roles: [roles.ROLE_IMT_INSTANCES_RESTORE],
                color: 'success',
                icon: 'fal fa-trash-restore',
                sandboxOnly: false,
            },
        },
    }),
    computed: {
        ...mapGetters({ instanceErrors: 'instance/getErrors' }),
        statusAttr() {
            return this.statusMap[this.status]
        },
        statusText() {
            return upperCase(this.statusMap[this.status]?.text)
        },
        confirmText() {
            const text = this.statusMap[this.status]?.text

            return `Would you like to ${text} this instance?`
        },
        validStatus() {
            const isSandbox = this.instance.application.includes('sandbox')

            return this.statusAttr.sandboxOnly ? isSandbox : true
        },
    },
    methods: {
        ...mapActions({ patch: 'instance/patchStatus' }),
        submit: async function () {
            const { instance } = await this.patch({
                id: this.instance.id,
                data: { status: this.status },
            })

            if (!instance) {
                const instanceErrors = this.instanceErrors?.join(', ')
                await errorSnackbar({
                    subtext: `Instance state update failed. Errors: ${instanceErrors}`,
                })
                return
            }

            await successSnackbar({
                subtext: `Instance status updated to ${instance.status}`,
            })

            if (this.withRedirect) {
                const { path } = safeReferer({ name: 'imt-instances' })
                await this.$router.push({ path })
            }
        },
    },
}
</script>