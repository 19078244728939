import { mapActions, mapGetters } from 'vuex'

const GRAPHQL_KEY = 'instanceStateOptions'

export default {
    created: function () {
        // fetch options.
        if (!this.getConfiguration(GRAPHQL_KEY)) {
            this.get(GRAPHQL_KEY)
        }
    },
    methods: {
        ...mapActions({ get: 'configuration/get' }),
    },

    computed: {
        ...mapGetters({ getConfiguration: 'configuration/getConfiguration' }),
        ...mapGetters({ getLoading: 'configuration/getLoading' }),
        availableStates() {
            let allStates = this.getConfiguration(GRAPHQL_KEY)
            if (!allStates) {
                return []
            }
            if (this.instance.application in allStates) {
                return allStates[this.instance.application]
            }

            return allStates.default
        },
        loading() {
            return this.getLoading('instanceStateOptions')
        },
    },
}
