export default (products) => [
    {
        headerName: 'Id',
        field: 'id',
        sortable: false,
        initialHide: true,
    },
    {
        headerName: 'Product',
        field: 'productId',
        sortable: false,
        valueFormatter: (params) => products[params.value]?.name ?? params.value,
    },
    {
        headerName: 'License Count',
        field: 'licenseCount',
    },
    {
        headerName: 'Start Date',
        field: 'startDate',
    },
    {
        headerName: 'End Date',
        field: 'endDate',
    },
    {
        headerName: 'Uninstall Date',
        field: 'uninstallDate',
        valueFormatter: (params) => (params.value ? params.value : '-'),
    },
]
