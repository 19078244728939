<template>
    <ag-grid-server-side
        id="subscriptions"
        :server-side-datasource="serverSideDatasource"
        :column-defs="columnDefs(products)"
    >
        <template #controls>
            <product-select />
        </template>
    </ag-grid-server-side>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import columnDefs from '@/helpers/ag-grid/columnDef/imt/subscriptions'
import pagination from '@/helpers/ag-grid/pagination/rest'
import { mapActions, mapGetters } from 'vuex'
import ProductSelect from './ProductSelect.vue'

export default {
    name: 'Subscriptions',
    components: {
        AgGridServerSide,
        ProductSelect,
    },
    props: {
        clientId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        columnDefs,
    }),
    computed: {
        ...mapGetters({
            items: 'subscriptions/getItems',
            metadata: 'subscriptions/getMetadata',
            products: 'product/getItemsById',
        }),
    },
    methods: {
        ...mapActions({
            cget: 'subscriptions/cget',
        }),
        serverSideDatasource: function () {
            const fetch = async (params) => {
                await this.cget({ params: { ...params, clientId: this.clientId } })

                return {
                    items: this.items,
                    metadata: this.metadata,
                }
            }

            return {
                getRows: async function (params) {
                    const { items, metadata } = await fetch(pagination(params))

                    params.successCallback(items, metadata.count)
                },
            }
        },
    },
}
</script>

<style></style>
