<template>
    <base-dialog v-model="opened" max-width="1360" :title="title" :confirm-action="submit">
        <template #activator="{ on, attrs }">
            <v-btn :attrs="attrs" color="primary" data-cy="ps-activator" v-on="on">
                Edit Products
            </v-btn>
        </template>
        <template #content>
            <card-select
                v-model="selected"
                style="margin: auto; max-width: 1152px"
                :options="products"
                :image-path="imagePath"
            />
        </template>
    </base-dialog>
</template>

<script>
import CardSelect from '@/components/form/complex/CardSelect'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog.vue'

export default {
    name: 'ProductSelector',
    components: {
        CardSelect,
        BaseDialog,
    },
    data: function () {
        return {
            selected: [],
            opened: false,
        }
    },
    computed: {
        ...mapGetters({
            getClientProducts: 'client/getClientProducts',
            products: 'product/getItems',
            productsById: 'product/getItemsById',
            getClientById: 'client/getItemById',
        }),
        clientId() {
            return this.$route.params.id
        },
        client() {
            return this.getClientById(this.clientId)
        },
        clientProducts() {
            return this.getClientProducts(this.clientId)
        },
        title() {
            return 'Edit Products | ' + this.client?.name
        },
    },
    watch: {
        clientProducts() {
            this.selected = this.clientProducts.map((p) => p.productId)
        },
    },
    mounted() {
        this.getProducts({})
        this.selected = this.clientProducts.map((p) => p.productId)
    },
    methods: {
        ...mapActions({
            getProducts: 'product/cget',
            addClientProducts: 'client/addProducts',
            deleteClientProducts: 'client/deleteProducts',
        }),
        imagePath(id, name) {
            const value = this.productsById[id]
            if (value.application === 'allthedata') {
                try {
                    return require(`@/assets/product_logos/${value.name}.png`)
                } catch {}
            }
            try {
                return require(`@/assets/product_logos/${value.application}.png`)
            } catch {
                console.warn(`missing asset for ${value.application}.png`)
                return require(`@/assets/IE_logo_mark.png`)
            }
        },
        removed() {
            return this.clientProducts
                .filter((x) => !this.selected.includes(x.productId))
                .map((x) => x.id)
        },

        added() {
            return this.selected.filter((x) => !this.clientProducts.find((p) => x === p.productId))
        },
        submit() {
            const addedIds = this.added()
            const removedIds = this.removed()
            const promises = []

            if (addedIds.length > 0) {
                promises.push(
                    this.addClientProducts({
                        clientId: this.clientId,
                        data: addedIds,
                    })
                )
            }

            if (removedIds.length > 0) {
                promises.push(
                    this.deleteClientProducts({
                        clientId: this.clientId,
                        data: removedIds,
                    })
                )
            }

            Promise.all(promises)
        },
    },
}
</script>
<style></style>
