<template>
    <parent-layout :title="title">
        <template #actions>
            <div class="d-flex">
                <v-btn
                    v-if="isView"
                    class="mr-1"
                    color="primary"
                    :disabled="!hasEditRole"
                    :to="{ name: 'client-edit' }"
                >
                    Edit
                </v-btn>
                <v-btn
                    v-if="client"
                    color="error"
                    :to="{ name: 'imt-authentications', params: { id: client.id } }"
                    :disabled="!hasAuthViewRole"
                >
                    Authentications
                </v-btn>
            </div>
        </template>
        <extended-form
            :form-ready="form.ready"
            :form-data="form.data"
            :disabled="!disableForm || clientFetching || clientSaving"
            :show-submit="!isView"
            :check-dirty="!isView"
            :submit="submit"
        >
            <h4>Information</h4>
            <v-row>
                <v-col>
                    <text-field
                        required
                        :form="form"
                        :readonly="isView"
                        :disabled="isView"
                        field-key="name"
                        label="Legal Name"
                        data-cy="legal-name"
                    />
                </v-col>
                <v-col>
                    <text-field
                        required
                        :form="form"
                        :readonly="isView"
                        field-key="displayName"
                        :disabled="isView"
                        data-cy="display-name"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field
                        :form="form"
                        :readonly="isView"
                        :disabled="isView"
                        field-key="crmAccountNumber"
                        label="CRM Account Number"
                    />
                </v-col>
                <v-col>
                    <text-field
                        :form="form"
                        :readonly="isView"
                        :disabled="isView"
                        field-key="sfAccountNumber"
                        label="SF Account Number"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <text-field
                        :form="form"
                        :readonly="isView"
                        :disabled="isView"
                        field-key="ncesId"
                        label="NCES Id"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <auto-complete
                        required
                        :form="form"
                        field-key="state"
                        label="State"
                        :items="states"
                        outlined
                        hide-details="auto"
                        item-text="name"
                        item-value="abbr"
                        :disabled="isView"
                    />
                </v-col>
                <v-col>
                    <text-field
                        :form="form"
                        :readonly="isView"
                        field-key="county"
                        :disabled="isView"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field label="Address" :form="form" disabled field-key="street" />
                </v-col>
                <v-col>
                    <text-field :form="form" disabled field-key="city" data-cy="city" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <text-field :form="form" disabled field-key="zipCode" data-cy="zip" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h4>Status</h4>
                    <v-switch
                        v-model="form.data.status"
                        label="Enabled"
                        true-value="enabled"
                        false-value="disabled"
                        :readonly="isView"
                    />
                </v-col>
            </v-row>
            <template #cancel-actions>
                <v-btn outlined @click="cancel">Cancel</v-btn>
            </template>
        </extended-form>
        <v-row v-if="isView || isEdit" class="mt-2">
            <v-col v-if="getRouteId">
                <client-instance-list :client-id="getRouteId" />
            </v-col>
        </v-row>
    </parent-layout>
</template>

<script>
import security from '@/helpers/security'
import {
    ROLE_IMT_CLIENTS_CREATE,
    ROLE_IMT_CLIENTS_EDIT,
    ROLE_IMT_AUTHENTICATIONS_VIEW,
} from '@/helpers/security/roles'
import ExtendedForm from '@/components/form/ExtendedForm'
import ParentLayout from '@/components/layout/ParentLayout'
import TextField from '@/components/form/TextField'
import { mapActions, mapGetters } from 'vuex'
import { formSnackbar } from '@/helpers/snackbar'
import { between } from '@/helpers/form/validation'
import AutoComplete from '@/components/form/AutoComplete'
import { safeReferer } from '@/helpers/router'
import ClientInstanceList from '@/views/Imt/Clients/ClientInstanceList'

export default {
    components: {
        AutoComplete,
        TextField,
        ParentLayout,
        ExtendedForm,
        ClientInstanceList,
    },
    data: () => ({
        form: {
            data: {
                // client
                name: null,
                displayName: null,
                status: 'enabled',
                sfAccountNumber: null,
                crmAccountNumber: null,
                ncesId: null,
                state: null,
                county: null,
                street: null,
                city: null,
                zipCode: null,
            },
            rules: {
                name: [(v) => !!v || 'Legal Name is required'],
                displayName: [(v) => !!v || 'Display Name is required'],
                state: [(v) => !!v || 'State is required'],
                // This will be added back in at a later date when CRM is solely being used.
                // crmAccountNumber: [
                //     (v) => between(v, 1, 255),
                //     (v) => !!v || 'CRM account number is required',
                // ],
                ncesId: [(v) => between(v, 1, 7)],
            },
            errors: [],
            ready: false,
        },
        sandboxClient: null,
        sandboxInstances: null,
        instanceTab: null,
        client: null,
    }),
    computed: {
        ...mapGetters({
            getClientById: 'client/getItemById',
            clientProducts: 'client/getClientProducts',
            clientFetching: 'client/getFetching',
            clientSaving: 'client/getSaving',
            states: 'usStates/getStates',
            instances: 'instance/getItems',
        }),
        getRouteId() {
            return this.$route.params.id
        },
        isEdit() {
            return this.$route.name === 'client-edit'
        },
        isView() {
            return this.$route.name === 'client-view'
        },
        title() {
            switch (true) {
                case this.isView:
                    return 'Client View'
                case this.isEdit:
                    return 'Client Edit'
                default:
                    return 'Client Create'
            }
        },
        hasCreateRole() {
            return security.hasRole(ROLE_IMT_CLIENTS_CREATE)
        },
        hasEditRole() {
            return security.hasRole(ROLE_IMT_CLIENTS_EDIT)
        },
        disableForm() {
            return this.isEdit ? this.hasEditRole : this.hasCreateRole
        },
        hasAuthViewRole() {
            return security.hasRole(ROLE_IMT_AUTHENTICATIONS_VIEW)
        },
        isSandboxClient() {
            return this.client?.name?.includes('SANDBOX')
        },
        hasDNA() {
            return !!this.clientProducts(this.getRouteId).find(
                ({ productApp }) => productApp === 'allthedata'
            )
        },
    },
    async mounted() {
        const id = this.getRouteId
        if (id) {
            await Promise.all([
                // get client data
                (async () => {
                    this.client = await this.fetchClient(id)
                    this.setFormData(this.client)
                })(),

                // get client products
                this.getClientProducts(id),
            ])
        }

        if (await this.clientErrors()) {
            return
        }

        this.form.ready = true
    },
    methods: {
        ...mapActions({
            fetchClient: 'client/get',
            postClient: 'client/post',
            patchClient: 'client/patch',
            getClientProducts: 'client/getProducts',
            clientErrors: 'client/errors',
        }),
        async submit() {
            // client data
            const data = { client: this.form.data }

            const { client } = this.isEdit
                ? await this.patchClient({ id: this.getRouteId, data })
                : await this.postClient({ data })

            this.setFormData(client)

            await formSnackbar({
                isEdit: this.isEdit,
                type: 'Client',
                identifier: client.name,
            })

            if (!this.isEdit) {
                // redirect on client create
                await this.$router.push({ path: `/client/${client.id}` })
            }
        },
        async cancel() {
            const { path } = safeReferer({ name: 'imt-clients' })
            await this.$router.push({ path })
        },
        setFormData(data) {
            for (const key in this.form.data) {
                this.form.data[key] = data[key]
            }
        },
    },
}
</script>
