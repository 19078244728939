import roles from '@/helpers/security/roles'

export default [
    {
        headerName: 'Id',
        field: 'id',
        width: 350,
        sortable: false,
        initialHide: true,
    },
    {
        headerName: 'Client Id',
        field: 'clientId',
        width: 350,
        sortable: false,
        initialHide: true,
    },
    {
        headerName: 'Display Name',
        field: 'displayName',
        width: 220,
    },
    {
        headerName: 'Application',
        field: 'application',
    },
    {
        headerName: 'District ID',
        field: 'subdomain',
    },
    {
        headerName: 'State',
        field: 'state',
    },
    {
        headerName: 'Url',
        field: 'url',
        cellRenderer: (params) => `<a href="${params.value}" target="_blank">${params.value}</a>`,
    },
    {
        headerName: 'Database Host',
        field: 'databaseHost',
    },
    {
        headerName: 'Database Name',
        field: 'databaseName',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRendererFramework: 'ChipCellInstanceStatus',
    },
    {
        cellRendererFramework: 'InstanceListActions',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'instance-view',
                    role: roles.ROLE_IMT_INSTANCES_VIEW,
                    btnText: 'View',
                    icon: 'fal fa-eye',
                },
                {
                    routerLinkIds: ['id'],
                    routeName: 'instance-edit',
                    role: roles.ROLE_IMT_INSTANCES_EDIT,
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
            ],
            deleteButton: {
                actionParams: ['id'],
                namespace: 'instance',
                vuexMethod: 'remove',
                title: 'Instance',
                roles: roles.ROLE_IMT_INSTANCES_DELETE,
            },
        },
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
