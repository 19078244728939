<template>
    <v-list-item v-bind="$attrs" :disabled="!hasRole(allowedRoles) || disabled" v-on="$listeners">
        <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
    </v-list-item>
</template>

<script>
import allowedRolesMixin from '@/components/security/allowedRolesMixin'

export default {
    name: 'GatedListItem',
    mixins: [allowedRolesMixin],
}
</script>
