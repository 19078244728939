<template>
    <base-dialog
        v-model="opened"
        :title="title"
        :loading="fetching"
        confirm-text="Confirm"
        :confirm-action="submit"
        :disable-confirm="!allowCreate"
        v-on="$listeners"
    >
        <template v-slot:activator="{ on, attrs }">
            <gated-list-item
                :allowed-roles="roles"
                style="vertical-align: middle"
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-title>
                    <v-icon size="14" left color="#050F2D" style="min-width: 16px">
                        fal fa-shovel
                    </v-icon>
                    Create Sandbox
                </v-list-item-title>
            </gated-list-item>
        </template>

        <template v-slot:content>
            <div class="ma-4">Would you like to create a sandbox instance?</div>
            <v-container v-if="sandboxInstance">
                <alert-message type="error">
                    A sandbox already exists for this instance!
                    <v-row>
                        <v-col offset-sm="1" sm="2" class="pb-0">Instance</v-col>
                        <v-col class="pb-0">
                            <router-link
                                target="_blank"
                                :to="{ name: 'instance-view', params: { id: sandboxInstance.id } }"
                            >
                                {{ sandboxInstance.displayName }}
                            </router-link>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col offset-sm="1" sm="2" class="pt-0">Client</v-col>
                        <v-col class="pt-0">
                            <router-link
                                target="_blank"
                                :to="{ name: 'client-view', params: { id: sandboxClient.id } }"
                            >
                                {{ sandboxClient.name }}
                            </router-link>
                        </v-col>
                    </v-row>
                </alert-message>
            </v-container>
        </template>
    </base-dialog>
</template>
<script>
import roles from '@/helpers/security/roles'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog'
import { errorSnackbar, successSnackbar } from '@/helpers/snackbar'
import GatedListItem from '@/components/security/GatedListItem'
import AlertMessage from '@/components/alert/AlertMessage'
import { safeReferer } from '@/helpers/router'

export default {
    name: 'InstanceStatusAction',
    components: { AlertMessage, GatedListItem, BaseDialog },
    props: {
        instance: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        withRedirect: { type: Boolean, default: false },
    },
    data: () => ({
        opened: false,
        roles: [roles.ROLE_IMT_INSTANCES_SANDBOX_CREATE],
        client: null,
        sandboxClient: null,
        sandboxInstance: null,
        allowCreate: false,
    }),
    computed: {
        ...mapGetters({
            instanceErrors: 'instance/getErrors',
            getInstancesByClient: 'instance/getItemsByField',
            getClientById: 'client/getItemById',
            getClientByName: 'client/getItemsByField',
            fetchingClient: 'client/getFetching',
            fetchingInstance: 'instance/getFetching',
        }),
        title() {
            return `Create Sandbox Instance for ${this.instance.displayName}`
        },
        fetching() {
            return this.fetchingClient || this.fetchingInstance
        },
    },
    watch: {
        opened: async function (val) {
            if (!val) {
                return
            }
            /*
             * Before allowing a sandbox to be created we need to if an sandbox already exists.
             *   - Fetch a client with SANDBOX appended to the client name.
             *   - If this client exists, then fetch all their instances.
             *   - Check if the returned instances contain a matching sandbox instance
             */
            await this.fetchClients({ params: { search: { name: this.client.name } } })
            const resp = this.getClientByName(`${this.client.name} SANDBOX`, 'name')
            if (!resp.length) {
                this.allowCreate = true
                return
            }
            this.sandboxClient = resp[0]

            await this.fetchInstances({ clientId: this.sandboxClient.id })
            const sandboxInstances = await this.getInstancesByClient(
                this.sandboxClient.id,
                'clientId'
            )
            if (!sandboxInstances.length) {
                this.allowCreate = true
                return
            }

            this.sandboxInstance = sandboxInstances.find(
                (instance) =>
                    instance.application.includes(this.instance.application) &&
                    instance.application.includes('sandbox')
            )

            this.allowCreate = this.sandboxInstance === null
        },
    },
    async mounted() {
        const { clientId } = this.instance
        await this.fetchClient(clientId)
        this.client = this.getClientById(clientId)
    },
    methods: {
        ...mapActions({
            postSandbox: 'instance/postSandbox',
            fetchClient: 'client/get',
            fetchClients: 'client/cget',
            fetchInstance: 'instance/get',
            fetchInstances: 'instance/cget',
        }),
        submit: async function () {
            const { instance, client } = await this.postSandbox({
                parentClientId: this.instance.clientId,
                id: this.instance.id,
                sandboxClientId: this.sandboxClient?.id,
            })

            if (!instance) {
                const instanceErrors = this.instanceErrors?.join(', ')

                await errorSnackbar({
                    subtext: `Instance sandbox creation failed ${instanceErrors}`,
                })
                return
            }

            await successSnackbar({
                subtext: `Sandbox "${instance.displayName}" for ${client.displayName} created`,
            })

            if (this.withRedirect) {
                const { path } = safeReferer({ name: 'imt-instances' })
                await this.$router.push({ path })
            }
        },
    },
}
</script>