<template>
    <div>
        <div v-if="!clientId">No valid client associated with this district</div>
        <div v-else>
            <v-tabs v-model="instanceTab" class="mb-4">
                <v-tab> Products and Subscriptions </v-tab>
                <v-tab>{{ isSandboxClient ? 'Sandbox' : '' }} Instance List</v-tab>
                <v-tab v-if="hasDNA && !isSandboxClient" :disabled="!sandboxClient">
                    Sandbox Instance List
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="instanceTab">
                <v-tab-item>
                    <subscriptions-table :client-id="clientId" />
                </v-tab-item>
                <v-tab-item>
                    <instance-table title="Instance List" :query="{ clientId: clientId }" />
                </v-tab-item>
                <v-tab-item>
                    <instance-table
                        v-if="sandboxClient"
                        title="Sandbox Instance List"
                        :query="{ clientId: sandboxClient.id }"
                        :allow-create="false"
                    >
                        <template v-slot:actions>
                            <v-btn
                                link
                                small
                                target="_blank"
                                class="primary"
                                :to="{
                                    name: 'client-view',
                                    params: { id: sandboxClient.id },
                                }"
                            >
                                View Client
                            </v-btn>
                        </template>
                    </instance-table>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import InstanceTable from '@/views/Imt/Instances/List'
import { mapActions, mapGetters } from 'vuex'
import SubscriptionsTable from '@/views/Imt/Clients/SubscriptionsTable'

export default {
    components: { InstanceTable, SubscriptionsTable },
    props: {
        clientId: {
            type: String,
            default: null,
            required: true,
        },
    },
    data: () => ({ instanceTab: null, sandboxClient: null, client: null }),
    computed: {
        ...mapGetters({
            getClientByName: 'client/getItemsByField',
            clientProducts: 'client/getClientProducts',
            clientFetching: 'client/getFetching',
        }),
        isSandboxClient() {
            return this.client?.name?.includes('SANDBOX')
        },
        hasDNA() {
            return !!this.clientProducts(this.clientId).find(
                ({ productApp }) => productApp === 'allthedata'
            )
        },
    },
    async mounted() {
        this.client = await this.fetchClient(this.clientId)

        if (this.client && this.hasDNA) {
            this.sandboxClient = await this.getSandbox()
        }

        await this.getClientProducts(this.clientId)
    },
    methods: {
        ...mapActions({
            fetchClient: 'client/get',
            fetchClients: 'client/cget',
            getClientProducts: 'client/getProducts',
        }),
        async getSandbox() {
            await this.fetchClients({
                params: { search: { name: this.client.name } },
            })
            let resp = this.getClientByName(`${this.client.name} SANDBOX`, 'name')

            return resp[0]
        },
    },
}
</script>
