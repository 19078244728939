<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon>fal fa-ellipsis-v</v-icon></v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="prop in routeProps"
                :key="prop.route"
                :disabled="prop.disabled || !isAllowed(prop)"
                :to="prop.route"
                link
                style="vertical-align: middle"
            >
                <v-list-item-title>
                    <v-icon v-if="prop.icon" size="14" left color="#050F2D" style="min-width: 16px">
                        {{ prop.icon }}
                    </v-icon>
                    {{ prop.btnText }}
                </v-list-item-title>
            </v-list-item>
            <delete-dialog
                v-if="params.deleteButton && isDeletable"
                :delete-action="deleteItem"
                :delete-text="deleteText"
                :title="`Delete ${deleteTitle}?`"
            >
                <template v-slot:default="{ on, attrs }">
                    <v-list-item color="danger" :disabled="!canDelete" v-bind="attrs" v-on="on">
                        <v-list-item-title style="vertical-align: middle">
                            <v-icon size="14" color="#050F2D" style="min-width: 16px" left>
                                fal fa-trash
                            </v-icon>
                            Delete
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </delete-dialog>
            <instance-status-actions :instance="instance" list-items />
        </v-list>
    </v-menu>
</template>

<script>
import rowRouteActionsMixins from '@/components/table/rowRouteActionsMixins'
import rowDeleteActionsMixins from '@/components/table/rowDeleteActionsMixins'
import InstanceStatusActions from '@/views/Imt/Instances/StatusActions'
import DeleteDialog from '@/components/table/DeleteDialog'
import roles from '@/helpers/security/roles'

export default {
    name: 'InstanceListActions',
    components: { InstanceStatusActions, DeleteDialog },
    mixins: [rowRouteActionsMixins, rowDeleteActionsMixins],
    data: () => ({
        opened: false,
    }),
    computed: {
        instance() {
            return { ...this.params.data }
        },
        isDeletable() {
            const isSandbox = this.instance?.application?.includes('sandbox')

            return ['notDeployed', 'archived'].includes(this.instance.status) && !isSandbox
        },
    },
    methods: {
        //Additional checks specific to each button / action
        isAllowed(prop) {
            //Only apply these checks to the edit route
            if (prop.routeName === 'instance-edit') {
                return (
                    ['notDeployed'].includes(this.instance.status) ||
                    this.hasRole(roles.ROLE_IMT_INSTANCES_SUPER_ADMIN)
                )
            }

            return true
        },
    },
}
</script>
